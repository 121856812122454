import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/static-page.js";
import ProfilePic from "../components/bio";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const CTALink = makeShortcode("CTALink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`About`}</h1>
    <ProfilePic mdxType="ProfilePic" />
    <p>{`Hey, I’m Conor—a Minnesota-based frontend developer. I’m a sucker for hand-brewed coffee, good public transit, and typographic nerdery.`}</p>
    <p>{`I created this site fueled by nostalgia for the blogosphere plus inspiration from the `}<a parentName="p" {...{
        "href": "https://indieweb.org/"
      }}>{`IndieWeb movement`}</a>{` and a general resurgence of personal sites.`}</p>
    <p>{`You’ll find `}<Link to="/blog" mdxType="Link">{`sporadic thoughts`}</Link>{` on technology, writing, design, etc. and my `}<Link to="/reading" mdxType="Link">{`reading list`}</Link>{`, which includes books `}<em parentName="p">{`and`}</em>{` some gems from around the web.`}</p>
    <p>{`I’m available for limited freelance projects, especially if you’re working on anything related to climate or urban justice.`}</p>
    <CTALink to="/work" mdxType="CTALink">Learn more about my work</CTALink>
    <p>{`When I’m not in front of a computer, I like to spend time practicing flying trapeze, taking photos, cooking, and sailing.`}</p>
    <h3>{`Connect`}</h3>
    <p>{`You can find me on `}<a parentName="p" {...{
        "href": "https://twitter.com/co_or"
      }}>{`Twitter`}</a>{` and `}<a parentName="p" {...{
        "href": "http://github.com/conorsexton"
      }}>{`GitHub`}</a>{`. I also contribute the occasional photo to `}<a parentName="p" {...{
        "href": "https://unsplash.com/@conorsexton"
      }}>{`Unsplash`}</a>{`. For anything else, please feel free to `}<a parentName="p" {...{
        "href": "mailto:sextonc@me.com"
      }}>{`send email`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      